import React from "react"
import LoanCalculator from "../../../components/body/pages/personal-loan/personal-loan-calc"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const LoanCalculatorPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/personal-loan/loan-calculator/"}
      title="Loan Calculator | Visualize Your Payoff Timeline | Kuda"
      description="Plan your financial future with our interactive loan calculator. Visualize your payoff timeline, track progress, and optimize repayments. Make informed borrowing decisions today."
    />
    <LoanCalculator />
  </Layout>
)

export default LoanCalculatorPage
