import React, { useState } from "react"
import { SelectContainer, SelectIcon, StyledSelect } from "./personal-loans-styles"


const Dropdown = ({ options = [], defaultValue = "", onChange = () => {} }) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || (options[0]?.value ?? "")
  )

  const handleChange = event => {
    const newValue = event.target.value
    onChange(newValue)
    setSelectedOption(newValue)
  }

  return (
    <React.Fragment>
        <SelectContainer>
          <SelectIcon>
            <svg
              width="14"
              height="7"
              viewBox="0 0 14 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 7L13.9282 0.25H0.0717969L7 7Z" fill="black" />
            </svg>
          </SelectIcon>
          <StyledSelect
            className="kuda-dropdown-title"
            value={selectedOption}
            onChange={handleChange}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </StyledSelect>
        </SelectContainer>
    </React.Fragment>
  )
}

export default Dropdown
