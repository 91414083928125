import styled from "styled-components"

export const LoanCalcWrap = styled.div`
  background: var(--whiteAlt);
  border: 1px solid var(--borderOff);
  box-shadow: 15px 30px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 96px 32px 94px;

  @media only screen and (max-width: 767px) {
    padding: 32px 20px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    max-height: 630px;
  }
`
export const InputSectionWrap = styled.div`
  width: 375px;
  max-height: 378px;
  padding: 30px 18px;
  margin: auto 0px !important;
  border-radius: 5px;
  border: 1px solid rgba(219, 220, 224, 0.5);
  background: var(--White, #fff);
  box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);

  @media only screen and (max-width: 767px) {
    margin: 0px;
    border: none;
    background: var(--White, #fff);
    box-shadow: none;
  }

    .repayment-h3 {
        font-size: 20px;
        line-height: 25px;
    }
    span.input-range__label-container,
    .amount-input,
    .amount-input::placeholder {
        font-family: 'Muli';
      }
  /*InputRange styles*/
  .input-range {
    margin-top: 1rem !important;
  }

  .input-range__label--value {
    display: none;
  }

  .input-range__label--max {
    right: 30px;  
  }

  .input-range__label--min {
    left: 10px;  
  }

  .input-range__track {
    height: 24px !important;
    border-radius: 20px;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #1dcbef !important;
  }
  .input-range__slider {
    background: #1dcbef !important;
    border: 1px solid #40196D4D;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    width: 42px !important;
    height: 42px !important;
    margin-top: -34px !important;
    margin-left: -12px !important;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 35%;
      left: 35%;
      transform: rotate(45deg);
      width: 12px;
      height: 12px;
      background-color: white;
      border-radius: 0; 
  }

  /*END: InputRange styles*/

  .card-group {
    width: 100%;
    position: relative;

  }

  @media only screen and (max-width: 425px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    max-height: 630px;
  }
`
export const LoanDetails = styled.div`
  .loan-principal {
    color: #1dcbef;
    font-weight: 900;
  }

  .loan-figures {
    font-weight: 900;
    color: var(--blackColor);
  }
`
export const SummaryDetailsWrap = styled.div`
  .summary-details-text {
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
    color: var(--blackColor);
    text-align: center;
    max-width: 285px;
  }
`
export const OutputWrap = styled.div`
  width: 377px;
  margin: 77px 0px;

  @media only screen and (max-width: 767px) {
    margin: 0px;
  }

  h2 {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    color: var(--blackColor);
  }

  .narration {
    text-align: center !important;
  }

  .loan-icon {
    font-size: 11px;
    font-weight: 700;
    color: var(--textColor);
  }

  .loan-icon svg {
    transform: translateY(2.5px);
  }

  .emphasized-amts {
    font-weight: 800;
  }

  .disclaimer {
    font-weight: 400;
    border-top: 1px solid var(--offWhiteTrans);
    padding: 8px 13px !important;
  }
`
export const ScrollContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    justify-content: center;
    bottom: 57%;
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    z-index: 99;
  }
`

export const ScrollButtonWrapper = styled.div`
  position: relative;
  width: 66.82px;
  height: 28.84px;
  border-radius: 14px;
  border: 1.28px solid #dfe3ff;
  background-color: #ffffff;
  padding: 6.42px 15.41px;

  span {
    color: var(--primaryColor);
    font-family: Mulish;
    font-weight: 800;
    font-size: 13px;
    line-height: 16.32px;
    text-align: center;
  }
`

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  justify-content: space-between;
`

export const SelectIcon = styled.div`
  position: absolute;
  left: 8px;
  top: 16.5px;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
  width: 16px;
  height: 9px;
  padding-right: 20px;

  @media (max-width: 768px) {
    top: 45%;
  }
`

export const StyledSelect = styled.select`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 32px !important;
  background: var(--whiteAlt) !important;
  outline: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &::-ms-expand {
    display: none;
  }

  @media (max-width: 768px) {
    max-width: 50%;
    font-size: 12px;
  }
`