import React, { useEffect, useState } from "react"
import ScrollDown from "../../../../assets/img/scroll-down.inline.svg"
import { ScrollContainer, ScrollButtonWrapper } from "./personal-loans-styles"

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (!hasMounted) return

    const handleScroll = () => {
      const calculationsElement = document.getElementById("calculations")

      if (calculationsElement) {
        const calculationsRect = calculationsElement.getBoundingClientRect()
        setIsVisible(calculationsRect.top > window.innerHeight)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [hasMounted])

  const handleClick = () => {
    const calculationsElement = document.getElementById("calculations")
    if (calculationsElement) {
      calculationsElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  if (!hasMounted) {
    return null
  }

  return (
    <ScrollContainer isVisible={isVisible} onClick={handleClick}>
      <ScrollDown />
      <ScrollButtonWrapper>
        <span>Scroll</span>
      </ScrollButtonWrapper>
    </ScrollContainer>
  )
}

export default ScrollButton
